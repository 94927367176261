import type {DialogProps}             from "@mui/material";
import {
    Alert,
    Box,
    Modal
}                                     from "@mui/material";
import React                          from "react";
import {
    AddBoorprofiel,
    AddCadastralInfo,
    AddCompany,
    AddCompanyMeasurement,
    AddContact,
    AddControle,
    AddEnergySystem,
    AddMultipleWells,
    AddMultipleWellsCommand,
    AddNote,
    AddPermit,
    AddPermitWell,
    AddWell,
    AuthToken,
    baseUrl,
    Delete,
    UpdateBoorprofiel,
    UpdateCadastralInfo,
    UpdateChangeOwnership,
    UpdateCompany,
    UpdateCompanyMeasurement,
    UpdateContact,
    UpdateControle,
    UpdateEnergySystem,
    UpdateEnergySystemStatus,
    UpdateEnergySystemStatusToezicht,
    UpdateNote,
    UpdatePermit,
    UpdatePermitWell,
    UpdateWell
} from "../constants";
import {useGobesContext}              from "../context/GobesContextProvider";
import AddMultipleWellsForm from "../forms/AddMultipleWellsForm";
import BoorprofielForm                from "../forms/BoorprofielForm";
import BronForm                       from "../forms/BronForm";
import CadastralForm                  from "../forms/CadastralForm";
import ChangeOwnership                from "../forms/ChangeOwnership";
import CompanyForm                    from "../forms/CompanyForm";
import CompanyMeasurementForm         from "../forms/CompanyMeasurementForm";
import ContactForm                    from "../forms/ContactForm";
import ControleForm                   from "../forms/ControleForm";
import DeleteForm                     from "../forms/DeleteForm";
import EnergySystemForm               from "../forms/EnergySystemForm";
import EnergySystemStatusForm         from "../forms/EnergySystemStatusForm";
import EnergySystemStatusToezichtForm from "../forms/EnergySystemStatusToezichtForm";
import NotitieForm                    from "../forms/NotitieForm";
import PermitForm                     from "../forms/PermitForm";
import PermitWellForm                 from "../forms/PermitWellForm";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    maxHeight: "80vh",
    overflowY: "auto",
    overflowX: "auto",
    transform: 'translate(-50%, -50%)',
    maxWidth: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const UpsertObjectModal = () =>
{
    const {
        setModalVisible, modalVisible, modalAction,
        alertText, setAlertText,
        alertVisible, setAlertVisible
    } = useGobesContext()

    const handleClose: DialogProps["onClose"] = (event, reason) =>
    {
        if (reason && reason === "backdropClick")
        {
            return;
        }

        setModalVisible(false);
        setAlertVisible(false)
    }

    function CurrentForm()
    {
        switch (modalAction)
        {
            case AddCompany:
            case UpdateCompany:
                return <CompanyForm/>

            case AddContact:
            case UpdateContact:
                return <ContactForm/>

            case AddCadastralInfo:
            case UpdateCadastralInfo:
                return <CadastralForm/>

            case AddEnergySystem:
            case UpdateEnergySystem:
                return <EnergySystemForm/>

            case UpdateEnergySystemStatus:
                return <EnergySystemStatusForm/>

            case UpdateEnergySystemStatusToezicht:
                return <EnergySystemStatusToezichtForm/>

            case AddPermit:
            case UpdatePermit:
                return <PermitForm/>

            case AddWell:
            case UpdateWell:
                return <BronForm/>

            case AddBoorprofiel:
            case UpdateBoorprofiel:
                return <BoorprofielForm/>

            case AddControle:
            case UpdateControle:
                return <ControleForm/>

            case AddNote:
            case UpdateNote:
                return <NotitieForm/>

            case AddCompanyMeasurement:
            case UpdateCompanyMeasurement:
                return <CompanyMeasurementForm/>

            case AddPermitWell:
            case UpdatePermitWell:
                return <PermitWellForm/>

            case UpdateChangeOwnership:
                return <ChangeOwnership/>
            case Delete:
                return <DeleteForm/>

            case AddMultipleWells:
                return <AddMultipleWellsForm/>

        }
    }

    return (<>
            {/*// @ts-ignore*/}
            <Modal
                open={modalVisible}
                onClose={handleClose}

            >
                <Box sx={style}>

                    <Alert style={{
                        display: alertVisible
                                 ? "flex"
                                 : "none"
                    }} severity="error" color={"warning"}>{alertText}</Alert>

                    <html ig-base-url={baseUrl + "/ig"} ig-api-token={AuthToken}>
                    {CurrentForm()}
                    </html>
                </Box>
            </Modal>
        </>
    )
}
export default UpsertObjectModal
