import {
    Button,
    Grid,
    Typography
}                        from "@mui/material";
import React, {
    useEffect,
    useRef
}                        from "react";
import {
    DeactivateWellCommand,
    Delete,
    IGStyle,
    UpdateWell,
    Well,
    WellDetail
} from "../../constants";
import {useGobesContext} from "../../context/GobesContextProvider";
import {submitObject}    from "../../util";

const WellBlock = () =>
{
    const {
        companyDetails,
        selectedCompanyRecord,
        setFormData,
        setModalVisible,setDeleteCallback,
        setModalAction, setActivePage, setSubPage, selectedBronRecord,bronDetails,
        refreshResource, setRefreshResource, setAlertVisible, setAlertText, selectedEnergyRecord
    } = useGobesContext()

    const RDRef = useRef<any>()

    useEffect(() =>
    {
        switch (refreshResource)
        {
            case WellDetail:
                setTimeout(() => RDRef.current?.refresh(), 1000)
                break
        }
        setRefreshResource("")
    }, [refreshResource]);

    function UpdateObject()
    {
        setFormData(RDRef.current?.data)

        setModalVisible(true)
        setModalAction(UpdateWell)
    }

    function DeleteObject()
    {
        submitObject({id: RDRef.current?.data.id}, companyDetails?.id, DeactivateWellCommand).then(async (event: any) =>
        {
            HandleSubmit(event)
        })
        setActivePage(2)
        setSubPage(0)
        setRefreshResource(Well)
    }

    async function HandleSubmit(event: any)
    {
        //In case of a status that isnt 200, we most likely have received an error message.
        //So we display this as alert in the Modal
        if (event.status !== 200)
        {
            const response = await event.json()
            setAlertVisible(true)
            setAlertText(response.messages.reduceRight((acum: any, current: any) => acum.concat(current)))
        }
    }

    const handleStartDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
        setModalVisible(true)
        setModalAction(Delete)
        setDeleteCallback({delete: DeleteObject} )
    };

    return (<>
        <Grid container justifyContent="center" direction={"column"}>
            <Grid container className={"rm-container"} direction={"row"}>
                <Grid container>
                    <Typography variant="h4" marginRight={"auto"}>
                        Bronnen
                    </Typography>
                </Grid>

                <Grid item xs={8} container alignItems={"stretch"} className={"containerspacing"}>

                    {/*// @ts-ignore*/}
                    <ig-resource-detail lang="nl-NL" ref={RDRef} class={"ig-detail-remove-2rows"} style={IGStyle} resource-detail-id="WellRD" resource-id={bronDetails.id}/>

                </Grid>

                <Grid container item className={"button-container"} xs={3} marginLeft={1} justifyContent={"flex-start"}>

                    <Grid container item xs={4}>
                        <Grid container direction="column" item>
                            <Button variant={"contained"} onClick={UpdateObject}>Bron Bewerken (Toezicht)</Button>
                            <Button variant={"contained"} onClick={handleStartDelete}>Bron Verwijderen (Toezicht)</Button>
                        </Grid>
                    </Grid>

                </Grid>

            </Grid>
        </Grid>
    </>)
}

export default WellBlock