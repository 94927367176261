import {
    Button,
    Typography
}                        from "@mui/material";
import {
    useEffect,
    useRef
}                        from "react";
import {
    AddContactCommand,
    Contact,
    ContactDetail,
    UpdateContactCommand
}                        from "../constants";
import {useGobesContext} from "../context/GobesContextProvider";
import {submitObject}    from "../util";

const ContactForm = () =>
{
    const {companyDetails, setModalVisible, formData, setRefreshResource,
        setAlertText, setAlertVisible} = useGobesContext()
    const FormRef = useRef<any>()

    useEffect(() =>
    {
        if (FormRef.current !== undefined && formData !== undefined)
        {
            //FormRef.current.setData(data)
            FormRef.current.data = {
                phoneNumber: companyDetails.phone,
                email: companyDetails.email,
                ...formData
            }
        }
    }, []);


    async function HandleSubmit(event: any)
    {
        //In case of a status that isnt 200, we most likely have received an error message.
        //So we display this as alert in the Modal
        if (event.status === 200)
        {
            //If everything went okay we can close it
            setModalVisible(false)
            setAlertVisible(false)
        }
        else
        {
            let response = await event.json()
            setAlertVisible(true)
            setAlertText(response.messages.reduceRight((acum: any, current: any) => acum.concat(current)))
        }

    }

    function onClickSubmit()
    {
        let Data: any = {}
        // @ts-ignore
        FormRef.current.getData().then((event: any) => Data = event)
        // @ts-ignore
        FormRef.current.submitForm().then(() =>
            {
                if (formData === undefined)
                {
                    submitObject(Data, companyDetails?.id, AddContactCommand).then(async (event: any) =>
                    {
                        HandleSubmit(event)
                    })
                    setRefreshResource(Contact)

                }
                else
                {
                    submitObject(Data, companyDetails?.id, UpdateContactCommand).then(async (event: any) =>
                    {
                        HandleSubmit(event)
                    })
                    setRefreshResource(ContactDetail)

                }
            }
        )

    }

    return (<>

        <Typography id="modal-modal-title" variant="h6" component="h2">
            {formData === undefined
             ? "Contact Toevegen"
             : "Contact Bewerken"}
        </Typography>
        {/*// @ts-ignore*/}
        {formData === undefined
            // @ts-ignore
         ? <ig-form ref={FormRef} form-id="AddContact"/>
            // @ts-ignore
         : <ig-form ref={FormRef} form-id="UpdateContact"/>}
        <Button variant="contained" onClick={onClickSubmit}>Submit</Button>
    </>)
}
export default ContactForm