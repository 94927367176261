import {
    Button,
    Typography
}                        from "@mui/material";
import {
    useEffect,
    useRef
}                        from "react";
import {
    AddEnergySystemCommand,
    EnergySystem,
    EnergySystemDetail,
    UpdateEnergySystemCommand
}                        from "../constants";
import {useGobesContext} from "../context/GobesContextProvider";
import {submitObject}    from "../util";

const EnergySystemForm = () =>
{
    const {companyDetails, energyDetails, setModalVisible, formData,setRefreshResource,
        setAlertText, setAlertVisible} = useGobesContext()

    //<editor-fold desc="Changeable Variables">
    const AddTitle = "Energiesysteem toevoegen"
    const EditTile = "Energiesysteem bewerken"

    const AddForm = "AddEnergySystem"
    const UpdateForm = "UpdateEnergySystem"

    const AggregateDetails = companyDetails
    const ExtraFormData = formData
    const SubmitButton = "Submit"

    const DetailRefresh = EnergySystemDetail
    const MasterRefresh = EnergySystem

    const AddCommand = AddEnergySystemCommand
    const UpdateCommand = UpdateEnergySystemCommand

    useEffect(() =>
    {
        if (FormRef.current !== undefined && ExtraFormData !== undefined)
        {
            FormRef.current.data = {
                address: companyDetails.address,
                ...ExtraFormData
            }
        }
    }, []);
    //</editor-fold>

    //<editor-fold desc="Form - Does not need changing">
    const FormRef = useRef<any>()

    async function HandleSubmit(event: any)
    {
        //In case of a status that isnt 200, we most likely have received an error message.
        //So we display this as alert in the Modal
        if (event.status === 200)
        {
            //If everything went okay we can close it
            setModalVisible(false)
            setAlertVisible(false)
        }
        else
        {
            let response = await event.json()
            setAlertVisible(true)
            setAlertText(response.messages.reduceRight((acum: any, current: any) => acum.concat(current)))
        }

    }

    function onClickSubmit()
    {
        let Data: any = {}
        // @ts-ignore
        FormRef.current.getData().then((event: any) => Data = event)
        // @ts-ignore
        FormRef.current.submitForm().then(() =>
            {
                if (formData === undefined)
                {
                    submitObject(Data, AggregateDetails?.id, AddCommand).then(async (event: any) =>
                    {
                        HandleSubmit(event)
                    })
                    setRefreshResource(MasterRefresh)

                }
                else
                {
                    submitObject(Data, AggregateDetails?.id, UpdateCommand).then(async (event: any) =>
                    {
                        HandleSubmit(event)
                    })
                    setRefreshResource(DetailRefresh)

                }
            }
        )

    }

    return (<>

        <Typography id="modal-modal-title" variant="h6" component="h2">
            {ExtraFormData === undefined
             ? AddTitle
             : EditTile}
        </Typography>
        {/*// @ts-ignore*/}

        {ExtraFormData === undefined
            // @ts-ignore
         ? <ig-form ref={FormRef} form-id={AddForm}/>
            // @ts-ignore
         : <ig-form ref={FormRef} form-id={UpdateForm}/>}
        <Button variant="contained" onClick={onClickSubmit}>{SubmitButton}</Button>
    </>)
    //</editor-fold>
}
export default EnergySystemForm